<template>
  <div class="r-month-picker">
    <CSelect
      :value="input.month"
      label="Month"
      :options="months"
      @update:value="handleUpdate($event, input.year)"
    />
    <CSelect
      label="Year"
      :value="input.year"
      :options="years"
      @update:value="handleUpdate(input.month, $event)"
    />
  </div>
</template>

<script>
/**
 * Month/year picker component.
 */
export default {
  name: 'RMonthPicker',
  props: {
    input: {
      type: Object,
      default: () => {
        const today = new Date()
        return {
          month: today.getMonth(),
          year: today.getFullYear()
        }
      }
    },
    months: {
      type: Array,
      default: () => [ new Date().getMonth() ]
    },
    years: {
      type: Array,
      default: () => [ new Date().getFullYear() ]
    }
  },
  methods: {
    /**
     * Emit input event to be handled by the parent.
     *
     * @param {Number} month
     * @param {Number} year
     */
    handleUpdate (month, year) {
      this.$emit('input', { month, year })
    }
  }
}
</script>

<style lang="scss" scoped>
.r-month-picker {
  display: flex;

  .form-group {
    margin-right: 10px;
  }
}
</style>
