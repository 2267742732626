<template>
  <div>
    <v-date-picker
      v-bind="$attrs"
      :popover="{ visibility: 'click', placement: 'bottom-end' }"
      :columns="$screens({ default: 1, lg: 2 })"
      mode="range"
      class="r-date-picker"
      @input="$emit('input', $event)"
      @dayclick="$emit('dayclick', $event)"
    >
      <div class="input">
        {{ formattedDateRange }}
        <CIcon name="md-expand-more" />
      </div>
    </v-date-picker>
  </div>
</template>

<script>
/**
 * Wrapper component for v-calendar's date-picker
 * component which uses the default slot to render
 * the datepicker as a button instead of the usual
 * date input box.
 *
 * Passes all props through to v-date-picker, see
 * v-calendar docs for the available options:
 *
 * https://docs.vcalendar.io/api/datepicker.html#props
 */
import { format, getDate, getMonth, getYear } from 'date-fns'

export default {
  name: 'RDateRangePicker',
  computed: {
    formattedDateRange () {
      const start = this.$attrs.value.start
      const end = this.$attrs.value.end

      const sameDay = getDate(start) === getDate(end)
      const sameMonth = getMonth(start) === getMonth(end)
      const sameYear = getYear(start) === getYear(end)

      const startDay = format(start, 'd')
      const startMonth = format(start, 'MMMM')
      const startYear = format(start, 'yyyy')
      const endDay = format(end, 'd')
      const endMonth = format(end, 'MMMM')
      const endYear = format(end, 'yyyy')

      if (sameYear && sameMonth && sameDay) {
        return `${startMonth} ${startDay}, ${startYear}`
      }
      if (sameYear && sameMonth) {
        return `${startMonth} ${startDay}-${endDay}, ${startYear}`
      }
      if (sameYear) {
        return `${startMonth} ${startDay} - ${endMonth} ${endDay}, ${startYear}`
      }
      return `${startMonth} ${startDay}, ${startYear} - ${endMonth} ${endDay}, ${endYear}`
    }
  }
}
</script>

<style lang="scss" scoped>
.r-date-picker {
  cursor: pointer;

  .input {
    font-weight: bold;
    font-size: 1.1rem;
  }
}
</style>
