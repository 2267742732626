<template>
  <div>
    <CRow align-horizontal="between" class="mb-2">
      <CCol sm="auto" class="d-flex align-items-center">
        <h3>
          Price Updating Report
        </h3>
      </CCol>
      <CCol sm="auto" class="d-flex align-items-center">
        <div class="pr-2 daterange-select">
          Showing reports for
        </div>
        <RDateRangePicker
          v-model="dateRange"
          :max-date="new Date()"
        />
      </CCol>
    </CRow>
    <CRow class="mt-3">
      <CCol>
        <RSpinnerOverlay
          :loading="loading"
          :opacity="0.8"
          :z-index="1028"
          :is-full-page="true"
        >
          <router-view
            ref="report"
            :date-range="tzDateRange"
            :loading="loading"
            @loading="loading = $event"
          />
        </RSpinnerOverlay>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { subDays } from 'date-fns'

import { RDateRangePicker } from '@/components/dates'
import { RSpinnerOverlay } from '@/components/spinners'

export default {
  name: 'BaseReport',
  components: {
    RSpinnerOverlay,
    RDateRangePicker
  },
  data () {
    return {
      dateRange: {
        start: subDays(new Date(), 1),
        end: new Date()
      },
      loading: false
    }
  },
  computed: {
    tzDateRange () {
      return {
        ...this.dateRange,
        tz: Intl.DateTimeFormat().resolvedOptions().timeZone
      }
    },
    report () {
      return this.$route.name
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .input {
  color: $info;
}
.daterange-select {
  color: $info;
  font-size: 1.1rem;
}
</style>
